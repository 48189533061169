import { ForwardedRef, forwardRef } from "react";

type DatePickerCustomInputProps = { value: string; onClick: (event: React.MouseEvent<HTMLElement>) => void }

const DatePickerCustomInput = forwardRef(
  ({ value, onClick }: DatePickerCustomInputProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <button type="button" className={"date-picker-custom-input"} onClick={onClick} ref={ref}>
      {value}
    </button>
  )
)
export default DatePickerCustomInput;